// placeholder image when there is no service cases
@import './theme-vars.module';

.service-case-placeholder-image-wrapper {
  width: 100%;
  height: 86vh;
  position: relative;

  @media (min-width: 1450px) {
    height: 91vh;
  }

  .service-case-placeholder-image {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .message-box {
    padding: 25px 35px;
    // background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    // box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    .message-text {
      color: #333;
      font-size: 1.2rem;
    }
  }
}

.fileDrop {
  border: 1px dashed $grayMain;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  cursor:pointer;
  border: 1px dashed $grayDark;
}