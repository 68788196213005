// --------------------------------Spacing----------------------------------------------
//--------------------------------------------------------------------------------------
$scalingFactor: 8;

//----------------------------------Palette----------------------------------------------
//---------------------------------------------------------------------------------------

// NOTE: '#ffffff' in colors vars other than in $white are placeholders.

// Common
$black: #000000;
$white: #ffffff;

// Text
$textDark: #474747;
$textLight: #666666;

// primary
$primaryLighter: #e5f5f8;
$primaryLight: #7fd0de;
$primaryMain: #00a1be;
$primaryDark: #007085;
$primaryDarker: #00404c;

// secondary
$secondaryLighter: #fdf0e9;
$secondaryLight: #f4b695;
$secondaryMain: #ea6e2c;
$secondaryDark: #a34d1e;
$secondaryDarker: #46210d;

// info
$infoLighter: #e6eff9;
$infoLight: #80b1df;
$infoMain: #0063bf;
$infoDark: #004586;
$infoDarker: #00284c;

// success
$successLighter: #e9f9e6;
$successLight: #91df80;
$successMain: #23bf00;
$successDark: #157300;
$successDarker: #0a3900;

// warning
$warningLighter: #fdfaea;
$warningLight: #f5e796;
$warningMain: #ebce2d;
$warningDark: #8d7c1b;
$warningDarker: #463e0d;

// error
$errorLighter: #fdeaea;
$errorLight: #f59696;
$errorMain: #eb2d2d;
$errorDark: #8d1b1b;
$errorDarker: #460d0d;

// gray
$grayLighter: #f0f0f0;
$grayLight: #d1d1d1;
$grayMain: #999999;
$grayDark: #474747;
$grayDarker: #292929;
// commetchat
$cometChat: #bab3b3;

//----------------------------------Typography----------------------------------------------
//---------------------------------------------------------------------------------------

// Font family
$fontPrimary: Heebo, sans-serif;

// Font weight
$fontWeightRegular: 400;
$fontWeightMedium: 500;

// Font size (in pixels)
$h1FontSize: 40;
$h1LineHeight: 48;

$h2FontSize: 36;
$h2LineHeight: 44;

$h3FontSize: 32;
$h3LineHeight: 40;

$h4FontSize: 28;
$h4LineHeight: 36;

$h5FontSize: 24;
$h5LineHeight: 32;

$h6FontSize: 20;
$h6LineHeight: 28;

$body1FontSize: 16;
$body1LineHeight: 24;

$body2FontSize: 14;
$body2LineHeight: 20;

$captionFontSize: 12;
$captionLineHeight: 16;

//----------------------------------Border radius----------------------------------------------
//---------------------------------------------------------------------------------------
$roundedXs: 2px;
$roundedSm: 4px;

//----------------------------------Box shadow----------------------------------------------
//---------------------------------------------------------------------------------------
$shadowLow: 0px 0px 4px #00000066;
$shadowMedium: 0px 4px 8px #00000033;
$shadowHigh: 0px 8px 16px #0000001a;
$shadowInset: inset 0px 4px 8px #00000033;
$shadowFocus: 0px 0px 4px #00a1becc;

//----------------------------------Modal------------------------------------------------
//---------------------------------------------------------------------------------------
$modalMaxWidthSm: 600px;
$modalMaxWidthMd: 800px;
$modalContentHeight: '60vh';

//------------------------------------------------------------------------------------------------
//------------------------------------Layouts-----------------------------------------------------
//------------------------------------------------------------------------------------------------
$headerHeight: 60px;
$sidebarWidth: 100px;
$modalContentHeight: '60vh';

// ------------------------------ EXPORTS --------------------------------------------------------
// -----------------------------------------------------------------------------------------------

:export {
  // --------------------------------------------Spacing------------------------------------------

  scalingFactor: $scalingFactor;

  // --------------------------------------------Palette------------------------------------------

  // common
  black: $black;
  white: $white;

  // text
  textDark: $textDark;
  textLight: $textLight;

  // primary
  primaryLighter: $primaryLighter;
  primaryLight: $primaryLight;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primaryDarker: $primaryDarker;

  // secondary
  secondaryLighter: $secondaryLighter;
  secondaryLight: $secondaryLight;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondaryDarker: $secondaryDarker;

  // info
  infoLighter: $infoLighter;
  infoLight: $infoLight;
  infoMain: $infoMain;
  infoDark: $infoDark;
  infoDarker: $infoDarker;

  // success
  successLighter: $successLighter;
  successLight: $successLight;
  successMain: $successMain;
  successDark: $successDark;
  successDarker: $successDarker;

  // warning
  warningLighter: $warningLighter;
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;
  warningDarker: $warningDarker;

  // error
  errorLighter: $errorLighter;
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;
  errorDarker: $errorDarker;

  // gray
  grayLighter: $grayLighter;
  grayLight: $grayLight;
  grayMain: $grayMain;
  grayDark: $grayDark;
  grayDarker: $grayDarker;

  //----------------------------------Typography----------------------------------------------

  // Font family
  fontPrimary: $fontPrimary;

  // Font weight
  fontWeightRegular: $fontWeightRegular;
  fontWeightMedium: $fontWeightMedium;

  // Font size
  h1FontSize: $h1FontSize;
  h1LineHeight: $h1LineHeight;

  h2FontSize: $h2FontSize;
  h2LineHeight: $h2LineHeight;

  h3FontSize: $h3FontSize;
  h3LineHeight: $h3LineHeight;

  h4FontSize: $h4FontSize;
  h4LineHeight: $h4LineHeight;

  h5FontSize: $h5FontSize;
  h5LineHeight: $h5LineHeight;

  h6FontSize: $h6FontSize;
  h6LineHeight: $h6LineHeight;

  body1FontSize: $body1FontSize;
  body1LineHeight: $body1LineHeight;

  body2FontSize: $body2FontSize;
  body2LineHeight: $body2LineHeight;

  captionFontSize: $captionFontSize;
  captionLineHeight: $captionLineHeight;

  //----------------------------------Border Radius----------------------------------------------
  roundedXs: $roundedXs;
  roundedSm: $roundedSm;

  //----------------------------------Box Shadow----------------------------------------------
  shadowLow: $shadowLow;
  shadowMedium: $shadowMedium;
  shadowHigh: $shadowHigh;
  shadowInset: $shadowInset;
  shadowFocus: $shadowFocus;

  //----------------------------------Modal------------------------------------------------
  //---------------------------------------------------------------------------------------
  modalMaxWidthSm: $modalMaxWidthSm;
  modalMaxWidthMd: $modalMaxWidthMd;
  modalContentHeight: $modalContentHeight;

  //------------------------------------------------------------------------------------------------
  //------------------------------------Layouts-----------------------------------------------------
  //------------------------------------------------------------------------------------------------
  sidebarWidth: $sidebarWidth;
  headerHeight: $headerHeight;
  modalContentHeight: $modalContentHeight;
}
