.form-control {
  height: 40px;
  font-weight: 400;
  border-radius: 0.125rem;
  color: #333 !important;
  font-size: 0.875rem;
  border: none;
  background-color: #eceff1;

  // background-color: $white;
  &:focus {
    // background-color: transparent;
    // border-color: transparent !important;
    background-color: #eceff1;
    box-shadow: none !important;

    .search-xl & {
      outline: none;
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  .chat-room & {
    &:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &-lg {
    font-size: 1.125rem;
    height: 50px;
  }
}

.mention__highlighter {
  border: 0 !important;

  .text-link {
    z-index: 20;
    position: relative;
  }
}

.mention__highlighter,
.mention__input {
  overflow: hidden auto !important;
  @extend .form-control;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  height: 4rem;

  &:focus {
    outline: 1px solid gray !important;
  }
}

.note-expand {
  .mention__highlighter,
  .mention__input {
    height: 20rem !important;
  }
}

.add-note-section {
  .mention__suggestions {
    padding: 0;
    height: 200px;
    top: -215px !important;
    overflow: auto;
    min-width: 100% !important;
    border-radius: 0.125rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    ul {
      li {
        padding: 0.5rem;
        font-size: 0.9rem;

        &:hover {
          color: #ea6e2c !important;
          background-color: #f5f5f5;
          transition: 0.2s ease-out;
          box-shadow: none;
        }

        p {
          line-height: 1;
        }
      }
    }
  }
}

.form-mention {
  padding: 5px;

  .mention__highlighter,
  .mention__input {
    height: 28rem;

    &:focus {
      outline: 1px solid gray !important;
    }
  }

  .mention__suggestions {
    overflow: hidden auto;
    height: 200px;
    border-radius: 0.125rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    ul {
      li {
        padding: 0.5rem;
        font-size: 0.9rem;

        &:hover {
          color: #ea6e2c !important;
          background-color: #f5f5f5;
          transition: 0.2s ease-out;
          box-shadow: none;
        }

        p {
          line-height: 1;
        }
      }
    }
  }
}

.email-note-expand {
  .mention__highlighter,
  .mention__input {
    height: 10rem !important;
  }
}
