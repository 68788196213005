.medishield-paragraph {
  padding-left: 40px;
  margin-top: 10px;
  .span {
    font-weight: bold;
    margin-right: 10px;
  }

  .span1 {
    font-weight: bold;
    text-decoration: underline;
    margin-right: 10px;
  }
}

.medishield-description {
  .divider {
    margin-bottom: 10px;
  }
}
