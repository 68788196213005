@import './theme-vars.module';

// Styles for some adjustments in the mui components

.mui {
  &__standard-input-with-icon-prefix-wrapper {
    display: flex;
    align-items: flex-end;

    svg {
      margin-right: 0.5rem;
      margin-bottom: 0.625rem;
      color: $textLight;
    }
  }

  &__filled-input-with-icon-button-suffix-wrapper {
    position: relative;
    button {
      border-radius: 0;
      color: $textLight;
      background-color: $grayLighter;
      padding: 11px;
      position: absolute;
      top: 1px;
      right: 1px;
      svg {
        font-size: 16px;
      }

      &:hover {
        background-color: $grayLight;
      }
    }
  }
  &__filled-referral-input {
    position: relative;
    button {
      border-radius: 0;
      color: $textLight;
      background-color: $grayLight;
      padding: 11px;
      position: absolute;
      top: 0.8px;
      right: 1px;
      svg {
        font-size: 16px;
      }

      &:hover {
        background-color: $grayMain
      }
    }
  }
}
